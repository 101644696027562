import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  InputLabel,
  Modal,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getIdToken } from 'firebase/auth';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import firebaseUtil from '../../../../utils/firebaseUtil';
import { checkIfNumberExist } from '../../../../utils/formatObject';
import { getOtpErrorMessage } from '../../../../utils/formatString';
import palette from '../../../../theme/palette';
import { InputContainer, StyledInput, ThemeButton } from '../../../GlobalStyles';
import RegisterOTPForm from '../visitor/RegisterOTPForm';
import CustomModal from '../../../../components/CustomModal';
import PhonenumberTextField2 from '../../../../components/PhonenumberTextField2';
import uuidv4 from '../../../../utils/uuidv4';

// ----------------------------------------------------------------------
const FormContainer = styled('form')(({ theme }) => ({
  padding: '16px',
  borderRadius: '4px',
  maxWidth: '900px',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: '10px',
}));

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  opacity: 0.9,
  fontSize: '15px',
  color: '#fff',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  opacity: 0.9,
  fontSize: '.75rem',
  color: palette.visitor.errorText,
}));

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const defaultForm = {
  userPhoneNumber: '',
  captcha: '',
};

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const [error, setError] = useState('');

  const [formError, setFormError] = useState({});

  const [formData, setFormData] = useState({
    userPhoneNumber: '',
    captcha: '',
  });

  // const [isInit, setIsInit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // firebaseUtil.initVerifier();
    // setIsInit(firebaseUtil.getIsInitialized());
  }, []);

  useEffect(() => {
    if (openForgotPasswordDialog) {
      setTimeout(() => loadCaptchaEnginge(4), 100);
    }
  }, [openForgotPasswordDialog]);

  const handleFormChange = (e) => {
    // const regex = /^\d+$/;
    // if (e.target.name === 'userPhoneNumber' && e.target.value && !regex.test(e.target.value)) return;

    setFormData((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };

  const handleClose = () => {
    setOpenForgotPasswordDialog(false);
    setFormData(defaultForm);
  };

  const isValid = () => {
    const _errors = {};
    let valid = true;

    if (!formData?.userPhoneNumber) {
      valid = false;
      _errors.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    }

    if (validateCaptcha(formData?.captcha?.trim()) === false) {
      valid = false;
      setFormData((prev) => ({ ...prev, captcha: '' }));
      _errors.captcha = translate('captcha_not_match');
    }

    setFormError(_errors);

    return valid;
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    try {
      setIsLoading(true);

      if (!isValid()) return;

      const isExists = await checkIfNumberExist(formData?.userPhoneNumber);

      if (!isExists) throw new Error(translate('number_not_exists'));

      let fingerprint = localStorage.getItem('fingerprint');

      if (!fingerprint) {
        fingerprint = uuidv4();
        localStorage.setItem('fingerprint', fingerprint);
      }

      const response = await conObj.post('otp_request.json', {
        no_hp: formData?.userPhoneNumber.replace(/[^0-9]/g, ''),
        fingerprint,
        purpose: 'forgotpassword',
      });

      navigate('/auth/reset-password', {
        state: {
          otpUuid: response.data?.data?.data?.otp_uuid,
          phoneNumber: formData?.userPhoneNumber.replace(/[^0-9]/g, ''),
        },
      });
    } catch (error) {
      console.error(error);
      setError(getOtpErrorMessage(translate, error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={openForgotPasswordDialog} onClose={() => handleClose()} sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          backgroundColor: '#161f2c',
          borderRadius: '20px',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          overflow: 'auto',
          p: '50px 24px 24px',
          m: 'auto',
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          position: 'relative',
          marginTop: '50px',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenForgotPasswordDialog(false)}
        />

        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>Reset Password</Typography>
        <FormContainer onSubmit={onSubmit}>
          <Stack spacing={2}>
            {!!error && <Alert severity="error">{error}</Alert>}

            <Box sx={{ mb: '10px' }}>
              <InputContainer direction="row">
                {/* <StyledInput
                  name="userPhoneNumber"
                  value={formData?.userPhoneNumber}
                  onChange={handleFormChange}
                  fullWidth
                  placeholder={translate('phone_number')}
                /> */}
                <PhonenumberTextField2
                  value={formData?.userPhoneNumber}
                  placeholder={translate('phone_number')}
                  onChange={(value) => handleFormChange({ target: { value, name: 'userPhoneNumber' } })}
                  fullWidth
                />
              </InputContainer>
              {formError?.userPhoneNumber && <ErrorText>{formError?.userPhoneNumber}</ErrorText>}
            </Box>

            <Grid container>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <LoadCanvasTemplateNoReload />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <InputContainer direction="row">
                  <StyledInput
                    name="captcha"
                    placeholder="Enter text on the left"
                    value={formData?.captcha}
                    onChange={handleFormChange}
                  />
                </InputContainer>
                {formError?.captcha && <ErrorText>{formError?.captcha}</ErrorText>}
              </Grid>
            </Grid>

            <ThemeButton fullWidth type="submit" loading={isLoading} disabled={isLoading}>
              {translate('confirm')}
            </ThemeButton>
          </Stack>
        </FormContainer>
      </Box>
    </Modal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
