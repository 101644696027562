import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
// import {
//   loadCaptchaEnginge,
//   //   LoadCanvasTemplate,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';

import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import firebaseUtil from '../../../../utils/firebaseUtil';
import { getOtpErrorMessage } from '../../../../utils/formatString';
import RegisterOTPForm from './RegisterOTPForm';

import palette from '../../../../theme/palette';
import { InputContainer, StyledInput, ThemeButton } from '../../../GlobalStyles';
import PhonenumberTextField2 from '../../../../components/PhonenumberTextField2';
import conObj from '../../../../utils/connection-assistant';
import uuidv4 from '../../../../utils/uuidv4';
import useCountdown from '../../../../hooks/useCountdown';

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.visitor.errorText,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  // fontSize: '0.25rem',
  color: palette.visitor.dialog.title,
  fontWeight: 600,
}));

const BirthdayText = styled(Typography)(({ theme }) => ({
  // fontSize: '.25rem',
  color: palette.visitor.dialog.otherText,
  fontWeight: 900,
}));

const RegisterButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  background: palette.visitor.register.buttonBg,
  border: `2px solid ${palette.visitor.register.border}`,
  color: `${palette.visitor.register.color} !important`,
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `1.5px solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const LinkText = ({ children }) => <span style={{ fontWeight: 600, color: palette.visitor.link }}>{children}</span>;

const _registerForm = {
  userUserName: '',
  userPassword: '',
  userPhoneNumber: '',
  userFullName: '',
  // userEmail: '',
  userBirthday: null,
  userReferralCode: '',
  otpCode: '',
  captcha: '',
};

const Register = ({ setActiveTab, authData }) => {
  const dispatch = useDispatch();

  const { register } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [registerForm, setRegisterForm] = useState(_registerForm);

  const [formError, setFormError] = useState({});

  const [tncConfirmed, setTncConfirmed] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
  });

  const [validationErrors, setValidationErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [otpUuid, setOtpUuid] = useState(null);

  const [resendExpiry, setResendExpiry] = useState(null);

  const resendCountdown = useCountdown(resendExpiry);

  const [verification, setVerification] = useState({
    number: null,
    id: null,
    otpSent: false,
    isVerified: false,
  });

  const platformText = useMemo(() => {
    return authData?.platform.charAt(0).toUpperCase() + authData?.platform.slice(1);
  }, [authData]);

  const isNotValidSubmit = useMemo(() => {
    if (authData) {
      return (
        // !tncConfirmed ||
        !registerForm?.userUserName ||
        // !registerForm?.captcha ||
        // !registerForm?.userEmail ||
        !registerForm.userFullName ||
        !registerForm?.userPhoneNumber ||
        (otpUuid && !registerForm?.otpCode)
      );
    }

    return (
      // !tncConfirmed ||
      !registerForm?.userUserName ||
      // !registerForm?.captcha ||
      // !registerForm?.userEmail ||
      !registerForm.userFullName ||
      !registerForm?.userPassword ||
      !registerForm?.userPhoneNumber ||
      (otpUuid && !registerForm?.otpCode)
    );
  }, [registerForm, authData, otpUuid]);

  useEffect(() => {
    const _referralCode = localStorage.getItem('referralCode');

    if (_referralCode) {
      setRegisterForm((prevState) => ({ ...prevState, userReferralCode: _referralCode }));
    }

    // setTimeout(() => {
    //   loadCaptchaEnginge(4);
    // }, 1000);
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onRequestOtp = async () => {
    try {
      setResendExpiry(null);
      let fingerprint = localStorage.getItem('fingerprint');
      if (!fingerprint) {
        fingerprint = uuidv4();
        localStorage.setItem('fingerprint', fingerprint);
      }

      const response = await conObj.post('otp_request.json', {
        no_hp: registerForm.userPhoneNumber.replace(/[^0-9]/g, ''),
        fingerprint,
        purpose: 'register',
      });

      setOtpUuid(response.data?.data?.data?.otp_uuid);
      setResendExpiry(moment().add(1, 'minute').toISOString());
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    }
  };

  const onSubmit = async () => {
    try {
      if (!isValid()) return;

      setIsLoading(true);

      if (!otpUuid && !registerForm?.otpCode) {
        await onRequestOtp();
      } else {
        if (!registerForm?.otpCode) {
          throw new Error(translate('x_is_required', { x: 'OTP' }));
        }

        const requestData = {
          'user-username': registerForm?.userUserName,
          'user-password': registerForm?.userPassword.trim(),
          'user_profile-no_hp': registerForm?.userPhoneNumber,
          'user-full_name': registerForm?.userFullName,
          'user-otp_code': registerForm?.otpCode,
          // 'user-email': registerForm?.userEmail,
          // 'user_profile-birthday': moment(registerForm?.userBirthday).format('YYYY-MM-DD'),
          'user-referral_code': registerForm?.userReferralCode,
        };

        if (authData) {
          const _authData = JSON.parse(JSON.stringify(authData));
          delete _authData?.platform;
          requestData['user_third_party_login-platform'] = authData?.platform;

          requestData['user_third_party_login-data'] = JSON.stringify(_authData);
        }

        await register(requestData);
        const _referralCode = localStorage.getItem('referralCode');
        if (_referralCode) localStorage.removeItem('referralCode');
        dispatch(openLoginSignup({ open: false, isLogin: true }));
      }
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    setIsLoading(true);
    await onRequestOtp();
    setIsLoading(false);
  };

  useEffect(() => {
    isValid(true);

    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [registerForm]);

  useEffect(() => {
    if (authData) {
      setRegisterForm((prev) => ({
        ...prev,
        userFullName: `${authData?.first_name || ''} ${authData?.last_name || ''}`,
        // userEmail: `${authData?.email || ''}`,
      }));
    }
  }, [authData]);

  const isValid = (allowEmpty = false) => {
    let valid = true;
    const _error = {};

    // username
    if (!allowEmpty && registerForm?.userUserName?.trim()?.length < 3) {
      valid = false;
      _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else if (allowEmpty && registerForm?.userUserName?.trim() && registerForm?.userUserName?.trim()?.length < 3) {
      valid = false;
      _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else {
      _error.userUserName = '';
    }

    // password
    if (!allowEmpty && registerForm?.userPassword?.trim()?.length < 6 && !authData) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '6' });
    } else if (
      allowEmpty &&
      registerForm?.userPassword?.trim() &&
      registerForm?.userPassword?.trim()?.length < 6 &&
      !authData
    ) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '6' });
    } else {
      _error.userPassword = '';
    }

    // fullname
    if (!allowEmpty && !registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else if (allowEmpty && registerForm?.userFullName?.trim() && !registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else {
      _error.userFullName = '';
    }

    // email
    // if (!allowEmpty && !registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
    //   valid = false;
    //   _error.userEmail = translate('invalid_x', { x: translate('email') });
    // } else if (
    //   allowEmpty &&
    //   registerForm?.userEmail?.trim() &&
    //   !registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
    // ) {
    //   valid = false;
    //   _error.userEmail = translate('invalid_x', { x: translate('email') });
    // } else {
    //   _error.userEmail = '';
    // }

    // phone number
    const validateNumber = () => {
      if (!registerForm?.userPhoneNumber.trim()) {
        valid = false;
        _error.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
      } else if (
        process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH &&
        registerForm?.userPhoneNumber.trim().length < Number(process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH) + 3
      ) {
        valid = false;
        _error.userPhoneNumber = translate(`invalid_x_must_be_atleast_y_characters`, {
          x: translate('phone_number'),
          y: process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH,
        });
      } else if (
        process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH &&
        registerForm?.userPhoneNumber.trim().length > Number(process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH) + 3
      ) {
        valid = false;
        _error.userPhoneNumber = translate('phone_num_x_max', { x: process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH });
      } else {
        _error.userPhoneNumber = '';
      }
    };

    if (!allowEmpty) {
      validateNumber();
    }

    if (allowEmpty && registerForm?.userPhoneNumber.trim()) {
      validateNumber();
    }

    // birthday
    // if (!registerForm?.userBirthday) {
    //   valid = false;
    //   _error.userBirthday = translate('x_is_required', { x: translate('birthday') });
    // } else {
    //   _error.userBirthday = '';
    // }

    // if (validateCaptcha(registerForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  // const verifyOtp = async () => {
  //   if (!isValid()) return;

  //   try {
  //     if (verification?.id) {
  //       await firebaseUtil.handleVerifyOTP(verification?.id, verification?.otp);
  //       setVerification((prev) => ({ ...prev, isVerified: true }));

  //   onSubmit();
  //     }
  //   } catch (error) {
  //     const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
  //     enqueueSnackbar(getOtpErrorMessage(translate, error), snackbarOptions);
  //     throw new Error(getOtpErrorMessage(translate, error));
  //   }
  // };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '0.6rem' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}

      {authData ? (
        <Alert sx={{ width: '100%', my: '0.6rem' }}>
          {translate('linked_successfully_x', {
            x: platformText,
          })}
        </Alert>
      ) : (
        ''
      )}

      {!otpUuid ? (
        <>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.6rem' }}
          >
            <LabelText>{translate('Register')}</LabelText>
            <ThemeButton
              variant="contained"
              onClick={() => dispatch(openLoginSignup({ isLogin: true, open: false }))}
              sx={{ borderRadius: '0.6rem' }}
            >
              {translate('Back')}
            </ThemeButton>
          </Box>

          <Box sx={{ mb: '0.6rem' }}>
            <InputContainer direction="row">
              <StyledInput
                name="userUserName"
                value={registerForm?.userUserName}
                onChange={(e) => handleFormChange(e)}
                placeholder={translate('username')}
              />
            </InputContainer>
            {formError?.userUserName && <ErrorText>{formError?.userUserName}</ErrorText>}
          </Box>

          <Box sx={{ mb: '0.6rem' }}>
            <InputContainer direction="row">
              <PhonenumberTextField2
                name="userPhoneNumber"
                value={registerForm?.userPhoneNumber}
                placeholder={translate('phone_number')}
                onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
                fullWidth
              />
            </InputContainer>
            {formError?.userPhoneNumber && (
              <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPhoneNumber}</span>
            )}
          </Box>

          {!authData ? (
            <Box sx={{ mb: '0.6rem' }}>
              <InputContainer direction="row">
                <StyledInput
                  type={showPassword ? 'text' : 'password'}
                  name="userPassword"
                  value={registerForm?.userPassword}
                  onChange={(e) => handleFormChange(e)}
                  placeholder={translate('Password')}
                />
                <StyledIconify
                  icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              </InputContainer>
              {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
            </Box>
          ) : (
            ''
          )}

          <Box sx={{ mb: '0.6rem' }}>
            <InputContainer direction="row">
              <StyledInput
                name="userFullName"
                value={registerForm?.userFullName}
                onChange={(e) => handleFormChange(e)}
                placeholder={translate('full_name')}
              />
            </InputContainer>
            {formError?.userFullName && <ErrorText>{formError?.userFullName}</ErrorText>}
          </Box>

          <Box sx={{ '& .MuiPaper-root': { boxShadow: 'none !important' } }} mt={2}>
            <Accordion defaultExpanded={!!localStorage?.getItem('referralCode')}>
              <AccordionSummary
                expandIcon={<Iconify icon="ooui:expand" />}
                sx={{
                  p: 0,
                  minHeight: 'unset !important',
                  '& .MuiAccordionSummary-content': { my: '0 !important' },
                }}
              >
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 900 }}>
                  {translate('referral_code')} ({translate('Optional')})
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mt: 2 }}>
                <InputContainer direction="row">
                  <StyledInput
                    name="userReferralCode"
                    value={registerForm?.userReferralCode}
                    onChange={(e) => handleFormChange(e)}
                    placeholder={translate('please_input_your_x', { x: translate('referral_code') })}
                  />
                </InputContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }}>
            <FooterText>
              {translate('confirm_18')}{' '}
              <LinkText onClick={() => console.log('click terms')}>{translate('terms_of_service')}</LinkText>
            </FooterText>
          </Stack>

          <ThemeButton
            fullWidth
            onClick={() => onSubmit()}
            // onClick={() => verifyOtp()}
            disabled={isNotValidSubmit}
            loading={isLoading}
            sx={isNotValidSubmit ? { filter: 'grayscale(1)', color: '#dfd6d6!important' } : {}}
          >
            {translate('submit')}
          </ThemeButton>
        </>
      ) : (
        <>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.6rem' }}
          >
            <LabelText>{translate('input_otp')}</LabelText>
            <ThemeButton
              variant="contained"
              onClick={() => {
                setOtpUuid(null);
                setRegisterForm((prev) => ({ ...prev, otpCode: '' }));
              }}
              sx={{ borderRadius: '0.6rem' }}
            >
              {translate('Back')}
            </ThemeButton>
          </Box>
          <LabelText sx={{ fontWeight: 500, fontSize: '12px' }}>{translate('otp_sent')}</LabelText>
          <Box sx={{ my: '0.6rem' }}>
            <InputContainer direction="row">
              <StyledInput
                name="otpCode"
                value={registerForm?.otpCode}
                onChange={(e) => handleFormChange(e)}
                placeholder={translate('verification_code')}
              />
            </InputContainer>
          </Box>

          <Box sx={{ display: 'flex', mb: 2 }}>
            {resendCountdown?.seconds !== '00' ? (
              <>
                <FormHelperText>{translate('send_again_in_x', { x: resendCountdown.seconds })}</FormHelperText>
              </>
            ) : (
              <>
                <FormHelperText
                  sx={{ textDecoration: 'underline', fontWeight: 900, cursor: 'pointer' }}
                  onClick={!isLoading ? () => resendOtp() : null}
                >
                  {translate('send_again')}
                </FormHelperText>
              </>
            )}
          </Box>

          <ThemeButton
            fullWidth
            onClick={() => onSubmit()}
            // onClick={() => verifyOtp()}
            disabled={isNotValidSubmit}
            loading={isLoading}
            sx={isNotValidSubmit ? { filter: 'grayscale(1)', color: '#dfd6d6!important' } : {}}
          >
            {translate('sign_up')}
          </ThemeButton>
        </>
      )}

      <FooterText
        onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
        sx={{
          mt: 2,
        }}
      >
        {translate('already_have_account')} <LinkText>{translate('login')}</LinkText>
      </FooterText>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Register.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Register;
