import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Grid, InputBase, Link, Stack, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import firebaseUtil from '../../../../utils/firebaseUtil';
import palette from '../../../../theme/palette';
import useCountdown from '../../../../hooks/useCountdown';
import useLocales from '../../../../hooks/useLocales';
import { getOtpErrorMessage } from '../../../../utils/formatString';
import { checkIfNumberExist } from '../../../../utils/formatObject';
import { countries } from '../../../../_mock';

const OTPContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: '#30313c',
  borderRadius: '10px',
  padding: '10px',
  //   marginRight: '1rem',
  //   marginLeft: '1rem',
  marginTop: '1rem',
}));

const InputContainer = styled(Stack)(({ theme }) => ({
  opacity: 0.9,
  alignItems: 'center',
  borderRadius: '5px',
  height: '44px',
  padding: '0 5px',
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  opacity: 0.9,
  marginLeft: 8,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: '#000',
  backgroundColor: '#fff',
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.visitor.input.placeholder,
    },
  },
}));

const RegisterOTPForm = ({ phoneNumber = '', setVerification = () => {}, verification = {}, isForgot = false }) => {
  const { translate } = useLocales();

  const [otpError, setOTPError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [isInit, setIsInit] = useState(false);

  const verificationExpiry = useRef(null);

  const [resendExpiry, setResendExpiry] = useState(null);

  const countdown = useCountdown(verificationExpiry.current);

  useEffect(() => {
    firebaseUtil.initVerifier();
    setIsInit(firebaseUtil.getIsInitialized());
  }, []);

  useEffect(() => {
    if (verification?.id) {
      verificationExpiry.current = moment().add(10, 'minutes').toISOString();
    }
  }, [verification?.id]);

  const sendOtp = async () => {
    try {
      setIsLoading(true);

      const country = countries.find((c) => c.code === process.env.REACT_APP_COUNTRY_CODE);
      if (!phoneNumber) {
        throw new Error('Please input phone number above');
      } else if (country && !phoneNumber.startsWith(country.phone)) {
        throw new Error(translate('only_x_country_allowed', { x: country.label }));
      } else {
        const isExists = await checkIfNumberExist(phoneNumber);

        if (isExists && !isForgot) throw new Error(translate('number_exists'));
        if (!isExists && isForgot) throw new Error(translate('number_not_exists'));

        const verificationId = await firebaseUtil.handleSendOTP(phoneNumber);
        setVerification((prev) => ({ ...prev, id: verificationId }));
        verificationExpiry.current = moment().add(10, 'minutes').toISOString();
      }
    } catch (error) {
      console.error(error);
      setOTPError(getOtpErrorMessage(translate, error));
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      setIsLoading(true);
      if (firebaseUtil.getRecaptchaVerifier()) {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
      }

      const newVerificationId = await firebaseUtil.handleSendOTP(`+${phoneNumber}`);
      verificationExpiry.current = moment().add(10, 'minutes').toISOString();
      setResendExpiry(moment().add(1, 'minute').toISOString());
      setVerification((prev) => ({ ...prev, id: newVerificationId }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickButton = () => {
    setOTPError('');
    sendOtp();
  };

  return (
    <OTPContainer spacing={1}>
      {!isInit && <Alert color="warning">{translate('otp_not_found')}</Alert>}
      {isInit ? (
        <>
          <Typography fontSize={'14px'}>
            {verification?.id ? translate('otp_sent') : translate('press_get_code')}
          </Typography>
          {otpError && <Alert color="error">{otpError}</Alert>}

          <Box>
            <Grid container spacing={1}>
              <Grid item xs={isForgot ? 12 : 8}>
                <InputContainer direction="row">
                  <StyledInput
                    name="otp"
                    placeholder={translate('verification_code')}
                    value={verification?.otp}
                    onChange={(e) => setVerification((prev) => ({ ...prev, otp: e.target.value }))}
                  />
                </InputContainer>
              </Grid>
              {!isForgot ? (
                <Grid item xs={4}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    sx={{
                      background: 'linear-gradient(0deg, rgba(63, 134, 237, 1) 0%, rgba(9, 185, 252, 1) 100%)',
                      boxShadow: 'none',
                      height: '100%',
                      textTransform: 'uppercase',
                      '&:hover': {
                        background: 'linear-gradient(0deg, rgba(63, 134, 237, 1) 0%, rgba(9, 185, 252, 1) 100%)',
                      },
                      whiteSpace: 'nowrap',
                    }}
                    loading={isLoading}
                    disabled={verification?.id}
                    onClick={onClickButton}
                  >
                    {translate('get_code')}
                  </LoadingButton>
                </Grid>
              ) : (
                ''
              )}
            </Grid>

            {verification?.id && !verification?.isVerified ? (
              <>
                <Box>
                  <Typography variant="caption">{translate('your_otp_expire')} </Typography>
                  <Typography variant="caption">
                    {countdown.minutes}:{countdown.seconds}
                  </Typography>
                </Box>

                {!resendExpiry ? (
                  <Typography
                    variant="body2"
                    sx={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.6 : 1 }}
                  >
                    {translate('didnt_receive')}{' '}
                    <Link
                      color={'warning'}
                      onClick={() => (!isLoading ? resendOtp() : console.log('Already Sent'))}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      {translate('send_again')}
                    </Link>
                  </Typography>
                ) : (
                  <Typography variant="caption" sx={{ opacity: 0.6 }}>
                    {translate('resend_again')} {countdown.seconds}
                  </Typography>
                )}
              </>
            ) : (
              ''
            )}
          </Box>
        </>
      ) : (
        ''
      )}
    </OTPContainer>
  );
};

export default RegisterOTPForm;
