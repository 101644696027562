// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ms = {
  game: `Permainan`,
  games: `Permainan`,
  sport: `Sukan`,
  center: `Pusat`,
  all_games: `Semua permainan`,
  game_history: `Sejarah Permainan`,
  coupon_code: `Kod Kupon`,
  telegram: `Telegram`,
  customer_service: `Khidmat Pelanggan`,
  player: `Pemain`,
  winner: `Pemenang`,
  all_bets: `Semua Pertaruhan`,
  my_bets: `Pertaruhan Saya`,
  name: `Nama`,
  time: `Masa`,
  bet_amount: `Jumlah Pertaruhan`,
  prediction: `Ramalan`,
  payout: `Pembayaran`,
  login: `Log masuk`,
  register: `Daftar`,
  username_placeholder: `Nama pengguna`,
  please_input_your_x: `Sila Masukkan {{x}} Anda`,
  captcha: `CAPTCHA`,
  forgot_password: `Lupa kata laluan`,
  minor_note: `Untuk melawat laman web ini, pastikan anda berumur lebih dari 18 tahun dan bersetuju`,
  terms_of_service: `Syarat Perkhidmatan`,
  dont_have_account: `Tiada akaun?`,
  sign_up: `Daftar`,
  username: `Nama pengguna`,
  email: `E-mel`,
  full_name: `Nama penuh`,
  confirm_18: `Saya mengesahkan bahawa saya berumur 18 tahun dan saya telah membacanya`,
  already_have_account: `Sudah mempunyai akaun?`,
  share: `Kongsi`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Tambah lagi`,
  details: `Butiran`,
  helps: `MEMBANTU`,
  kyc_policy: `Dasar KYC`,
  privacy_policy: `Dasar Privasi`,
  responsible_gaming: `Permainan yang bertanggungjawab`,
  terms_and_condition: `Terma dan syarat`,
  faq: `FAQ`,
  about_us: `TENTANG KITA`,
  amount: `Jumlah`,
  extra: `Tambahan`,
  deposit_bonus: `Bonus Deposit`,
  do_not_participate: `Jangan sertai promosi`,
  payment_problem: `Masalah Pembayaran`,
  click_for_help: `Klik untuk mendapatkan bantuan`,
  deposit_notes: `Nota Deposit`,
  available_balance: `Baki yang ada`,
  withdraw: `tarik diri`,
  withdrawal_notes: `Nota Pengeluaran`,
  withdrawal_note1: `Bilangan pengeluaran semasa`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Withdrawal fee:`,
  min: `Min:`,
  max: `Maks:`,
  opening_times: `Masa pembukaan:`,
  notifications: `Pemberitahuan`,
  invalid_x: `{{x}} tidak sah`,
  captcha_not_match: `Captcha Tidak Padan`,
  invalid_x_must_be_atleast_y_characters: `{{x}} tidak sah, mestilah sekurang-kurangnya {{y}} aksara`,
  x_is_required: `{{x}} diperlukan`,
  phone_number: 'Nombor telefon',
  phone_num_x_max: `Nombor Telefon maksimum {{x}} digit`,
  email_sent: `E-mel dihantar, sila semak e-mel anda.`,
  enter_valid_email: `Sila masukkan e-mel yang sah.`,
  confirm: `Sahkan`,
  password_atleast_6_characters: `Kata laluan mestilah sekurang-kurangnya 6 aksara.`,
  password_reset_success: `Kejayaan tetapan semula kata laluan!.`,
  new_password: `Kata laluan baharu`,
  bank: 'Bank',
  bank_transfer: `Pemindahan bank`,
  bank_name: `Nama bank`,
  account_name: `Nama akaun`,
  account_number: `Account Number`,
  drop_or_select: `Lepaskan atau Pilih fail`,
  drop_files_here: `Lepaskan fail di sini`,
  transfer_to: `Pemindahan Kepada`,
  upload_receipt: `Muat Naik Resit`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tiada Promosi`,
  please_upload_receipt: `Sila muat naik resit`,
  bank_account: `Akaun bank`,
  add_account: `Tambah akaun`,
  account_holder_name: `Nama Pemegang Akaun`,
  name_of_the_account_holder: `Nama Pemegang Akaun`,
  fill_in_bank_account: `Isikan Akaun Bank`,
  withdraw_information: `Tarik balik Maklumat`,
  note: `Catatan`,
  amount_withdrawn: `Jumlah yang dikeluarkan`,
  amount_no_exceed_balance: `Jumlah Pengeluaran tidak boleh melebihi baki yang ada`,
  minimum_withdraw_amount_is_x: `Jumlah Pengeluaran Minimum ialah {{x}}`,
  transaction_history: `sejarah transaksi`,
  date_from: `Tarikh dari`,
  date_to: `Tarikh Kepada`,
  account_name_must_be_4: `Nama Akaun mestilah lebih besar daripada 4 panjang`,
  account_number_must_be_x: `Nombor akaun mestilah 10-14 panjang`,
  payment_method: `Kaedah Pembayaran`,
  no_bank_account: `Anda tidak mempunyai sebarang akaun untuk dikeluarkan, Tambah sekarang`,
  please_select_bank: `Sila pilih Akaun Bank`,

  bet_history: `Sejarah Pertaruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Kiraan Pertaruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Rebat`,
  turnover: `Perolehan`,
  game_category: `Kategori Permainan`,

  you_have_x_unread_messages: `Anda mempunyai {{x}} mesej yang belum dibaca`,
  deposit_amount_between_x_and_y: `Amaun deposit mestilah antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimum ialah {{x}}{{y}}`,

  // all
  all: `Semua`,
  title: `Tajuk`,
  date: `Tarikh`,
  type: `Taip`,
  status: `Status`,
  remark: `Teguran`,
  category: `kategori`,
  please_select_x: `Sila pilih {{x}}`,
  save: `Jimat`,

  change_password: `Tukar kata laluan`,
  current_password: `Kata Laluan Semasa`,
  confirm_password: `Sahkan Kata Laluan Baharu`,
  submit: `Hantar`,
  password_changed: `Kata Laluan Ditukar`,
  x_must_be_y_characters: `{{x}} mestilah sekurang-kurangnya {{y}} aksara`,
  reset_password: `Menetapkan semula kata laluan`,
  casino: `Kasino`,
  slots: `Slot`,
  live_casino: `Kasino Langsung`,
  fishing: `Memancing`,
  sports: `sukan`,
  cards: `Kad`,
  clear: `Jelas`,
  search: `Cari`,
  new: `Baru`,
  logout: `Log keluar`,
  choose_date: `Pilih Tarikh`,
  select_both_date: `Pilih kedua-dua Tarikh Dari dan Kepada`,
  from: `daripada`,
  to: `Kepada`,
  apply: `Mohon`,
  promotions: `Promosi`,
  learn_more: `KETAHUI LEBIH LANJUT`,
  password_doesnt_match: `Kata laluan tidak sepadan`,
  cancel: `Batal`,
  confirm_to_pay: `Sahkan Untuk Bayar`,
  deposit_amount: `Jumlah Deposit`,
  loading_please_wait: `Muat turun sila tunggu`,
  account: `Akaun`,
  profile: `Profil`,
  bank_list: `Senarai Bank`,
  in_maintenance: `Dalam Penyelenggaraan`,
  birthday: `Hari Jadi`,
  birthday_bonus: `Masukkan hari lahir anda untuk bonus! 🎉🎁`,
  referral_code: `Kod rujukan`,
  referral: `Rujukan`,
  my_referral: `Rujukan Saya`,
  my_claim: `Tuntutan Saya`,
  claim_profit: `TUNTUTAN UNTUNG`,
  available_profit: `Keuntungan yang Tersedia`,
  claimed_profit: `Keuntungan yang Dituntut`,
  all_time_profit: `Keuntungan Sepanjang Masa`,
  referral_note_on_x: `Peroleh keuntungan setiap kali rakan anda bermain di {{x}}`,
  tier: `Peringkat`,
  commission: `Suruhanjaya`,
  referred_member: `Ahli yang dirujuk`,
  profit_earned: `Keuntungan Diperolehi`,
  account_information: `Maklumat Akaun`,
  invite_friends: `Menjemput kawan-kawan`,
  how_it_work: `Bagaimana ia berfungsi?`,
  invite_note: `Dapatkan bonus rujukan eksklusif apabila rakan anda mendaftar menggunakan kod rujukan anda!`,
  share_via: `Kongsi melalui`,
  referral_link: `Pautan Rujukan`,
  total_profit: `Jumlah Keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tiada apa-apa untuk dituntut`,
  claim_history: `Sejarah Tuntutan`,
  downline: `Downline Saya`,
  last_x_digit_bank_ref_number: `{{x}} digit terakhir nombor Ruj Bank`,
  bank_ref_number_validation_x: `Nombor Rujukan Bank diperlukan dan mestilah {{x}} panjang aksara`,

  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  // app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  product: `product`,
  invoice: `invoice`,
  // details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  no_balance: 'Anda tidak mempunyai baki yang mencukupi untuk meneruskan',
};

export default ms;
