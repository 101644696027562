/* eslint-disable camelcase */
// @mui
import { useMemo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';
import conObj from '../../utils/connection-assistant';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '.7rem',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 'auto',
  height: 20,
}));

const CustomScrollbar = styled(Stack)({
  overflowY: 'hidden',
  scrollbarWidth: 'thin',
  scrollbarColor: 'grey #2b2b2b',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#2b2b2b',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f48fb1',
    borderRadius: '10px',
    border: '2px solid #2b2b2b',
  },
});

const SocialIcons = styled(Image)(({ theme }) => ({
  width: '23px',
  height: '24px',
  cursor: 'pointer',
}));

const Footer = () => {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [pages, setPages] = useState([]);

  const [activeCompanies, setActiveCompanies] = useState([]);

  const {
    config_footer_copyright,
    config_name,
    config_youtube,
    config_whatsapp,
    config_wechat,
    config_viber,
    config_twitter,
    config_telegram,
    config_messenger,
    config_line,
    config_instagram,
    config_facebook,
  } = siteSettings || {};

  useEffect(() => {
    /**
     * Get custom pages like TnC etc.
     */
    getPages();

    getActiveCompanies();
  }, []);

  const copyrightText = useMemo(() => {
    if (config_footer_copyright) {
      const newText = config_footer_copyright.replace('[year]', getYear(new Date())).replace('[name]', config_name);

      console.log(newText);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  // const openSocial = (link) => {
  //   window.open(
  //     link,
  //     '_blank'
  //     // 'noopener noreferrer'
  //   );
  // };

  const getPages = () => {
    conObj
      .post(`/pages.json`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.data?.length > 0) {
            setPages(res.data?.data?.data?.map((item) => ({ pageKey: item?.page_key, title: item?.title })));
          }
        }
      })
      .catch((err) => {
        console.log(`Error: ${err?.message}`);
      });
  };

  const getActiveCompanies = () => {
    conObj.post('/games/active_companies.json').then((res) => {
      if (res.status === 200) {
        setActiveCompanies(res.data?.data?.data);
      }
    });
  };

  const onOpenSocial = (link) => {
    window.open(link, '_blank');
  };

  const displayGameProvidersComponent = (items) => {
    const midIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midIndex);
    const secondHalf = items.slice(midIndex);

    return (
      <CustomScrollbar
        direction={'column'}
        spacing={1}
        sx={{
          overflowX: 'scroll',
          mt: 2,
          ml: 0,
          width: '100%',
          padding: '0 20px 20px 10px',
        }}
      >
        <Stack direction={'row'} spacing={1} sx={{ height: '42px' }}>
          {firstHalf.map((item, index) => (
            <Image key={index} src={item?.logo_url} alt="Providers" sx={{ minWidth: '96px', height: '42px' }} />
          ))}
        </Stack>

        <Stack direction={'row'} spacing={1} sx={{ height: '42px' }}>
          {secondHalf.map((item, index) => (
            <Image key={index} src={item?.logo_url} alt="Providers" sx={{ minWidth: '96px', height: '42px' }} />
          ))}
        </Stack>
      </CustomScrollbar>
    );
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Container maxWidth="lg" sx={{ backgroundColor: palette.footer.bgColor, p: '2rem 0 !important' }}>
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ ml: 2 }}>
          <Typography sx={{ color: 'white', fontWeight: 600, fontSize: '.85rem' }}>
            {translate('our_game_provides')}
          </Typography>
        </Stack>
        {activeCompanies && displayGameProvidersComponent(activeCompanies?.filter((f) => f?.exists === true))}
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: palette.footer.bgColor2,
          py: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <FooterText sx={{ textTransform: 'uppercase' }}>{translate('contact_us_24')}</FooterText>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '0.2rem' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              width: '1.3rem',
              height: '1.3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '.2rem',
            }}
          >
            <Iconify icon="heroicons-solid:chat" sx={{ color: 'black', fontSize: '1.2rem' }} />
          </Box>
          <FooterText sx={{ fontSize: '0.5rem' }}>{translate('live_chat')}</FooterText>
          <FooterText sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgb(219, 67, 20)', lineHeight: 1 }}>
            {translate('')}
          </FooterText>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ p: 2, background: palette.footer.bgColor3 }}>
        <Stack spacing={1} sx={{ mb: 4 }}>
          <FooterText>{translate('footer_text1')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text2')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text3')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text4')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text5')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text6')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text7')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text8')}</FooterText>
          <FooterText sx={{ fontWeight: 400 }}>{translate('footer_text9')}</FooterText>
          <FooterText>{translate('footer_text10')}</FooterText>
        </Stack>
        <Stack spacing={1}>
          <FooterText>{translate('gaming_license')}</FooterText>
          <Box>
            <Image
              src={require('../../assets/powered/gaming-curacao-logo.png')}
              sx={{ width: '128px', height: '41px' }}
            />
          </Box>

          <FooterText sx={{ fontSize: '0.7rem' }}>{translate('gaming_license_caption')}</FooterText>
        </Stack>

        <Stack spacing={1} sx={{ mt: 5 }}>
          <FooterText>{translate('certification')}</FooterText>
          <Box sx={{ display: 'flex' }}>
            <Image src={require('../../assets/powered/bmm.png')} sx={{ width: '45px', height: '26px' }} />
            <Image src={require('../../assets/powered/itech.png')} sx={{ width: '26px', height: '26px' }} />
          </Box>
          <Image src={require('../../assets/powered/verified.png')} sx={{ width: '96px', height: '24px' }} />
        </Stack>

        <Box>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Box>
                <Stack spacing={1} sx={{ mt: 5 }}>
                  <FooterText>{translate('security')}</FooterText>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Image
                        src={require('../../assets/powered/iovation.png')}
                        sx={{ width: '91px', height: '18px' }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Image
                        src={require('../../assets/powered/threat-metrix.png')}
                        sx={{ width: '115px', height: '27px' }}
                      />
                      {/* <FooterText sx={{ fontSize: '0.8rem', fontWeight: 300 }}>{translate('threatmetrix')}</FooterText> */}
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Stack spacing={1} sx={{ mt: 5 }}>
                  <FooterText>{translate('responsible_gaming')}</FooterText>
                  <Box sx={{ display: 'flex' }}>
                    <Box>
                      <Image src={require('../../assets/powered/18.png')} sx={{ width: '21px', height: '20px' }} />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                      <Image
                        src={require('../../assets/powered/gamcare-logo.png')}
                        sx={{ width: '16px', height: '21px' }}
                      />
                    </Box>
                    <Box>
                      <Image
                        src={require('../../assets/powered/be-gamble-aware-logo.png')}
                        sx={{ width: '29px', height: '25px' }}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Stack spacing={1} sx={{ mt: 5 }}>
          <FooterText>{translate('payment_method')}</FooterText>
          <Box style={{ marginTop: 10 }}>
            <Grid container spacing={1}>
              {_paymentMethod.map((src, idx) => (
                <Grid item key={idx}>
                  <StyledImage src={src} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>

        <Box sx={{ mt: 5 }}>
          <FooterText>{translate('app_download')}</FooterText>
          <Box
            onClick={() => {
              navigate('/download');
              window.location.reload();
            }}
            style={{ marginTop: 10 }}
          >
            <Image
              src={require('../../assets/download/android_download.png')}
              sx={{ height: '40px', width: '124px' }}
            />
          </Box>
        </Box>

        <Stack direction={'row'} spacing={1} sx={{ width: '100%', mt: '2rem' }}>
          {pages?.length > 0 &&
            pages.map((item, index) => (
              <Typography
                onClick={() => navigate(`/page/${item?.pageKey}`)}
                variant="caption"
                sx={{
                  cursor: 'pointer',
                  fontSize: '10px',
                  color: '#fff',
                  display: 'block',
                  fontWeight: 600,
                  width: 'auto',
                  textDecoration: 'underline',
                }}
              >
                {item?.title}
              </Typography>
            ))}
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '2rem' }}>
          <StyledTypography sx={{ fontSize: '.5rem' }}>{copyrightText}</StyledTypography>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {!!config_youtube && (
              <Box onClick={() => onOpenSocial(config_youtube)}>
                <SocialIcons src={require('../../assets/social/yt.png')} />
              </Box>
            )}

            {!!config_facebook && (
              <Box onClick={() => onOpenSocial(config_facebook)}>
                <SocialIcons src={require('../../assets/social/fb.png')} />
              </Box>
            )}

            {!!config_instagram && (
              <Box onClick={() => onOpenSocial(config_instagram)}>
                <SocialIcons src={require('../../assets/social/instagram.png')} />
              </Box>
            )}

            {!!config_whatsapp && (
              <Box onClick={() => onOpenSocial(config_whatsapp)}>
                <SocialIcons src={require('../../assets/social/wa.png')} />
              </Box>
            )}

            {!!config_wechat && (
              <Box onClick={() => onOpenSocial(config_wechat)}>
                <SocialIcons src={require('../../assets/social/wechat.png')} />
              </Box>
            )}

            {!!config_viber && (
              <Box onClick={() => onOpenSocial(config_viber)}>
                <SocialIcons src={require('../../assets/social/viber.png')} />
              </Box>
            )}

            {!!config_twitter && (
              <Box onClick={() => onOpenSocial(config_twitter)}>
                <SocialIcons src={require('../../assets/social/twitter.png')} />
              </Box>
            )}

            {!!config_telegram && (
              <Box onClick={() => onOpenSocial(config_telegram)}>
                <SocialIcons src={require('../../assets/social/telegram.png')} />
              </Box>
            )}

            {!!config_messenger && (
              <Box onClick={() => onOpenSocial(config_messenger)}>
                <SocialIcons src={require('../../assets/social/messenger.png')} />
              </Box>
            )}

            {!!config_line && (
              <Box onClick={() => onOpenSocial(config_line)}>
                <SocialIcons src={require('../../assets/social/line.png')} />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

const _paymentMethod = [
  require('../../assets/powered/payment/nagad.png'),
  require('../../assets/powered/payment/bkash.png'),
  require('../../assets/powered/payment/rocket.png'),
];
